<template>
  <div>
    <h2>{{ group.groupName }}
      <b-tooltip target="_self" :title="`Edit this group`">
        <button class="bi bi-pencil-square" @click="routeToEdit" alt="Edit Group"></button>
      </b-tooltip>
    </h2>
    <!--    <h3>-->
    <!--      <router-link :to="{name: 'EditGroup', params: {idGroup: idGroup}}">Edit</router-link>-->
    <!--    </h3>-->
  </div>
  <div class="row">
    <div class="col-4" style="text-align: left;">
      <h5>
        <span style="font-weight: bold;">Created On: </span>
        <span style="text-decoration: underline;">{{ group.createdOn }}</span>
      </h5>
      <h5>
        <span style="font-weight: bold;">Last Updated: </span>
        <span style="text-decoration: underline;">{{ group.lastUpdate }}</span>
      </h5>
    </div>
<!--    <div class="col-4">-->
<!--      <b-tooltip target="_self" :title="`Edit this group`">-->
<!--        <button class="button btn-primary" @click="routeToEdit">Edit</button>-->
<!--      </b-tooltip>-->
<!--    </div>-->
    <div class="col-4 offset-sm-4">
      <label>Filter</label>
      <input v-model="searchQuery" placeholder="First Name, Last Name, Email"/>
    </div>
  </div>
  <div class="mt-2 container" style="overflow-y: auto; height: 400px;">
    <table id="group-table" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th v-for="(value, name, index) in headers" :key="index" @click="sortTable(name)">{{ value }}
          <b-tooltip target="_self" :text="`Sort ${value}`" v-if="name !== 'remove'">
            <button
                :class="(name === sortVal || sortVal === 'lastName') && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"></button>
          </b-tooltip>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="user in filteredList" :key="user" style="text-align: left; vertical-align: center; ">
        <td v-for="(value, name, index) in headers" :key="index" >
          <router-link v-if="name === 'name'" :to="{name: 'ViewUser', params: {idUser: user.routeIdUser}}">
            {{ user.firstName }} {{
              user.lastName
            }}
          </router-link>
          <p v-else-if="name === 'remove'" style="text-align: center;">
            <b-tooltip target="_self" :title="`Remove ${user.firstName} ${user.lastName} from the current group`">
              <button class="bi bi-trash-fill" @click="removeUserHelper(user.idUser)"></button>
            </b-tooltip>
          </p>
          <p v-else>{{ user[name] }}</p>
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref} from "vue";
import {getGroupById, removeUserFromGroup} from "@/views/SuperUserViews/UserGroups/GroupApiRoutes";
import axios from "axios";
import {sortBy} from "lodash/collection";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "UserGroupView",
  props: {
    idGroup: Number
  },
  setup(props) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const group = ref({});
    const sortVal = ref("");
    const searchQuery = ref("");
    const userList = ref([]);
    const storedReverse = ref(false);

    const headers = ref({
      name: "Name",
      email: "Email",
      roleName: "Position",
      // firstReport: "First Report",
      // secondReport: "Second Report",
      // thirdReport: "Third Report",
      remove: "Remove"
      //  will add reports here
    })

    onBeforeMount(() => {
      getGroup()
    })

    async function getGroup() {
      while (userList.value.length > 0) userList.value.pop();
      await axios.get(getGroupById(), {
        params: {
          "_idGroup": props.idGroup
        },
        headers: {
          "Authorization": authToken.value
        }
      }).then((result) => {
        group.value = result.data;

        let tmp_arr = [];
        group.value.groupedUsers.forEach(user => {
          let tmp_user = {
            firstName: user.firstName,
            lastName: user.lastName,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            roleName: user.roleName,
            routeIdUser: btoa(user.idUser),
            idUser: user.idUser
          }
          tmp_arr.push(tmp_user);
        })
        userList.value = tmp_arr.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
      })
    }

    function removeUserHelper(idUser) {
      Swal.fire({
        title: 'Remove User From Group',
        text: 'This user will be removed from this group but their data will not be deleted',
        confirmButtonText: 'Okay',
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          removeUser(idUser);
        }
      })
    }

    async function removeUser(idUser) {
      await axios.delete(removeUserFromGroup(props.idGroup, idUser),
          {
            headers: {
              "Authorization": authToken.value
            }
          }).then(() => {
        getGroup();
      })
    }

    const filteredList = computed(() => {
      return userList.value.filter((user) => {
        const first_name = user.firstName.toLowerCase();
        const last_name = user.lastName.toLowerCase();
        const email = user.email.toLowerCase();
        const role = user.roleName.toLowerCase();

        return first_name.includes(searchQuery.value.toLowerCase()) || last_name.includes(searchQuery.value.toLowerCase())
            || email.includes(searchQuery.value.toLowerCase()) || role.includes(searchQuery.value.toLowerCase());
      })
    })

    function sortTable(col) {
      let col_check = col;
      if (col_check === "name") {
        col_check = "lastName";
      }

      if (col_check !== sortVal.value) {
        storedReverse.value = false;
        sortVal.value = col_check;
        userList.value = sortBy(userList.value, col_check);
      } else {
        storedReverse.value = !storedReverse.value;
        userList.value = userList.value.reverse();
      }
    }

    function routeToEdit() {
      router.push({name: 'EditGroup', params: {idGroup: props.idGroup}});
    }

    return {
      group,
      headers,
      filteredList,
      storedReverse,
      sortVal,
      searchQuery,
      removeUserHelper,
      sortTable,
      routeToEdit
    }
  }
}
</script>

<style scoped>

</style>